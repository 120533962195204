import { ready } from "./utils.js";

const OPAQUE = "opaque";

ready(() => {
  const header = document.getElementsByTagName("header")[0];

  if (header) {
    document.addEventListener("scroll", function () {
      if (window.scrollY > 100) {
        header.classList.add(OPAQUE);
      } else if (window.scrollY < 5) {
        header.classList.remove(OPAQUE);
      }
    });
  }
});
