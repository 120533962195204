import { ready } from "./utils.js";
import $ from "jquery";

ready(() => {
  const hamburger = document.getElementById("hamburger");

  if (hamburger) {
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");
    });

    $(document).on("close.zf.offCanvas", function() {
      hamburger.classList.remove("is-active");
    });
  }
});
